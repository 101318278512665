<template>
  <div class="md-app-container login-page">

    <md-card class="password_section">
      <div>
        <img src="../../assets/images/logoTDD.png" alt="" class="logo">
      </div>
      <md-ripple>
        <md-card-header>
          <p class="md-subhead">{{ $t('resetPass') }}</p>
          <p class="header_text">{{ $t('resetDesc') }}</p>
        </md-card-header>
          <form action="" @submit.prevent="requestResetPassword">
              <md-card-content>
                  <md-field md-inline class="email">
                      <label><span class="email_img"><img src="../../assets/images/log.png"></span>{{ $t('name') }}</label>
                      <md-input v-model="name" aria-required="true" required></md-input>
                  </md-field>
              </md-card-content>

        <md-card-actions class="button_part">
          <md-button class="md-raised" type="submit">{{ $t('resetPass') }}</md-button>
        </md-card-actions>
          </form>
        <Messages :messages="messages"></Messages>
        <Errors :errors="errors"></Errors>
      </md-ripple>
    </md-card>
    <div class="footer_section">
      <p>{{ $t('useRight') }}</p>
    </div>
  </div>
</template>

<script>
 import Errors from "@/components/Errors";
import Messages from "@/components/Messages";
import {messages} from "@/mixins/messages";
import {errors} from "@/mixins/errors";
import {printArray} from "@/mixins/printArray";
 import {mapActions,mapGetters} from 'vuex';

export default {
  name: "ForgotPassword",
  data() {
    return {
      errors: [],
      name: ''
    }
  },
  components: {
    Errors,
    Messages
  },
  mixins: [
    messages,
    errors,
    printArray
  ],
  computed: {
    ...mapGetters(['forgotPasswordMessage']),
  },

  methods: {
    ...mapActions(['forgotPassword']),
    requestResetPassword() {

      this.clearErrors()
      this.clearMessages()

      if (!this.isDisabled) {
        this.isDisabled = true

          this.$axios.post(process.env.VUE_APP_API_URL + "/auth/password/forgot", {
                  name: this.name
              },
              {
                  /*todo*/
                  headers: {
                      "Access-Control-Allow-Origin": "*",
                  }
              }
          )
            .then((response) => {
              this.isDisabled = false
              if (response.data.errors.length > 0) {

                this.setErrors(response.data.errors);
                this.isDisabled = false

              } else {

                this.addMessage(response.data.message);

              }
            })
            .catch(err => {
              this.isDisabled = false
              this.setErrors(err.response.data.errors);
            })
      }
    }
  }
}
</script>

<style scoped lang="scss">

  .md-card{
    padding: 30px 60px;
  }

  .login-page{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #054F63!important;
    flex-direction: column;
  }
  .logo{
    margin: 20px 0 30px 15px;
  }
  .md-subhead{
    font-weight: 900;
    font-size: 34px;
    line-height: 52px;
    color: #000000;
    opacity: unset;
  }
  .header_text{
    width: 315px;
  }
  .email{
    background: #FFFFFF;
    border: 1px solid #CDD0D9;
    box-sizing: border-box;
    border-radius: 6px;
  }
  .email >label {
    margin-top: -7px;
    margin-left: 16px;
  }
  .email_img {
    padding-right: 9px;
  }
  .button_part{
    justify-content: end;
    margin-left: 7px;
  }
  .md-raised{
    background: #EC8B31!important;
    border-radius: 6px;
    color: white!important;
    text-transform: capitalize;
    width: 160px;
    height: 36px;
  }
  .password_section{
    height: 450px;
    width: 450px;
  }
  .footer_section{
    margin-top: 85px;
    justify-content: center;
    display: flex;
  }
  .footer_section p{
    color: white;
    width: 61%;
    text-align: center;
  }
  .md-button-content{
    color: white!important;
  }
</style>